import axios from "axios";
import { SetAuthToken } from "./Config";
import useAuthStore, { TOKEN, apiCall } from "./stores/authstore";
import { toast } from "react-toastify";
import useErrorStore from "./stores/errorstore";
import useUserStore from "./stores/userstore";
import useActivityStore from "./stores/activitystore";
import useLockStore from "./stores/locksstore";

const useGenFetcher = () => {
	let { getErrorText, clearErrors } = useErrorStore(),
		{ getUsers } = useUserStore(),
		{ getActivity } = useActivityStore(),
		{ getLocks } = useLockStore(),
		{ getUser, getUserFail, getUserLoading } = useAuthStore();
	let loadUser = async () => {
		let token = localStorage.getItem(TOKEN);
		if (token) SetAuthToken(token);

		getUserLoading();
		clearErrors();
		try {
			let res = await axios.get(`/api/v1/user`);
			if (res?.data) {
				getUser(res.data);
				apiCall("get", "/api/v1/activity/manage-activities", null, getActivity);
				apiCall("get", "/api/v1/locks/retrieve-locks", null, getLocks);
				if (res?.data?.data?.privilege === "superadmin") {
					apiCall(
						"get",
						"/api/v1/management/manage-users?privilege=admin",
						null,
						getUsers
					);
				}
			} else {
				getUserFail();
			}
		} catch (err) {
			if (err) console.log({ error: err.response?.data, err });
			if (err?.response?.status === 429) toast.error(err?.response?.data);
			getUserFail();
			getErrorText(err?.response?.data?.message || "");
		}
	};
	return { loadUser };
};

export default useGenFetcher;
