import { Route, Routes } from "react-router-dom";
import PageRender from "./PageRender";
import MainApp from "./screens";
import MainApp2 from "./pages";
import MainApp3 from "./views";
import { ToastContainer } from "react-toastify";
import Sidebar from "./components/sidebar/sidebar";
import useAuthStore from "./data/stores/authstore";
import useErrorStore from "./data/stores/errorstore";
import { PrimaryBtn } from "./components/button";
import ModalContainer from "./components/modals/modalcontainer";

const Routers = () => {
	let { isAuth, user } = useAuthStore(),
		{ error, clearErrors } = useErrorStore();
	return (
		<>
			<ToastContainer />
			<Sidebar>
				<Routes>
					<Route
						path="/"
						element={
							isAuth && user?.privilege === "superadmin" ? (
								<MainApp2 />
							) : isAuth ? (
								<MainApp3 />
							) : (
								<MainApp />
							)
						}
					/>
					<Route path="/:page" element={<PageRender />} />
					<Route path="/:page/:id" element={<PageRender />} />
					<Route path="/:page/:id/:step" element={<PageRender />} />
				</Routes>
			</Sidebar>

			{error?.length > 0 && (
				<ModalContainer handleClose={() => clearErrors()}>
					<div className="tw-p-5">
						<h2 className="tw-font-bold satoshi tw-text-red-800 tw-text-2xl">
							Error
						</h2>
						<div className="downH2 tw-flex tw-flex-col">
							{error?.map((item, i) => (
								<p
									key={i}
									className="tw-fw-bold Lexend tw-text-center tw-w-100">
									<span className="fontInherit tw-me-2">
										{error?.length !== 1 && <>{i + 1}.</>}
									</span>{" "}
									{item?.message || item || null}{" "}
								</p>
							))}
							<div className=" tw-mt-4 tw-flex tw-justify-center tw-py-3">
								<PrimaryBtn
									onClick={clearErrors}
									text={"Close"}
									width={
										"tw-bg-main tw-w-full tw-h-12 tw-rounded-xl tw-font-medium tw-text-base tw-text-white"
									}
								/>
							</div>
						</div>
					</div>
				</ModalContainer>
			)}
		</>
	);
};

export default Routers;
