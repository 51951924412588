import { useState, useEffect } from "react";
import MainHeader from "../../components/mainheader";
import useLockStore from "../../data/stores/locksstore";
import { PageLoader } from "../../components/button/modalcontainer";
import { MainPaginate } from "../../components/button";
import { apiCall } from "../../data/stores/authstore";
import { SlLock } from "react-icons/sl";
import { TfiUnlock } from "react-icons/tfi";
import { PiPassword } from "react-icons/pi";
import { SuspendRestoreModal } from "../../components/modals/deletemodal";
import useErrorStore from "../../data/stores/errorstore";
import { toast } from "react-toastify";
import { AccessCodeModal } from "../../components/modals/create-user";
import { RiMenuLine } from "react-icons/ri";
import { BsGridFill } from "react-icons/bs";
import {
	MdSignalWifi3Bar,
	MdSignalWifiBad,
	MdBattery20,
	MdBattery30,
	MdBattery50,
	MdBattery60,
	MdBattery80,
	MdBattery90,
	MdBatteryFull,
} from "react-icons/md";
import { SearchInput } from "../../components/inputs/myinput";
import { IoEllipsisVerticalOutline } from "react-icons/io5";

export const MainLocks = () => {
	const [modal, setModal] = useState(""),
		[loading, setLoading] = useState(""),
		[isDeleted, setIsDeleted] = useState(null),
		init = {},
		[state, setState] = useState(init),
		textChange = e => {
			let { name, value } = e.target;
			setState({ ...state, [name]: value });
		};

	let [datum, setDatum] = useState(null),
		{
			isFound,
			mainSearch,
			data,
			getLocks,
			updateLock,
			resetLockSearch,
			getSearch,
			getSearchLock,
		} = useLockStore(),
		[search, setSearch] = useState("");

	useEffect(() => {
		if (isFound)
			setDatum(
				mainSearch?.sort((a, b) => {
					let nameA = a?.display_name,
						nameB = b?.display_name;
					var textA = nameA?.toUpperCase();
					var textB = nameB?.toUpperCase();
					return textA < textB ? -1 : textA > textB ? 1 : 0;
				})
			);
		else
			setDatum(
				data?.sort((a, b) => {
					let nameA = a?.display_name,
						nameB = b?.display_name;
					var textA = nameA?.toUpperCase();
					var textB = nameB?.toUpperCase();
					return textA < textB ? -1 : textA > textB ? 1 : 0;
				})
			);
	}, [data, isFound, mainSearch]);

	useEffect(() => {
		if (search) {
			document.getElementById("Search").addEventListener("search", () => {
				resetLockSearch();
			});
			let handleSubmit = async () => {
				if (!search) return;
				let newSearch = search;
				getSearch(search);

				let mainSearch = data?.filter(it =>
					it?.display_name?.toLowerCase()?.includes(search?.toLowerCase())
				);
				getSearchLock({ data: mainSearch, search: newSearch });

				// await handleLoadMore();
			};
			handleSubmit();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search]);

	useEffect(() => {
		apiCall("get", "/api/v1/locks/retrieve-locks", null, getLocks);
		resetLockSearch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let [range] = useState(10),
		[display, setDisplay] = useState("list"),
		{ returnErrors } = useErrorStore();

	let handleDelete = async e => {
		e?.preventDefault();
		let errArr2 = [];
		if (modal === "access_code") {
			if (!state?.code) errArr2?.push("Access Code required");
			if (!state?.type) errArr2?.push("Access Type is required");
		}
		if (errArr2?.length > 0) return returnErrors(errArr2);

		setLoading(modal);
		let { response, errArr, errMsg } = await apiCall(
			"post",
			`/api/v1/locks/${
				modal === "access_code" ? "lock-access-code" : "lock-unlock-doors"
			}`,
			{
				...state,
				type: modal === "access_code" ? state?.type : modal,
				device_id: isDeleted?.device_id,
			}
		);
		// console.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			setModal("done");
			updateLock(response);
			return;
		}
		setLoading(false);
	};

	const [itemOffset, setItemOffset] = useState(0);
	const endOffset = itemOffset + range;

	if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.length;
		setItemOffset(newOffset);
	};

	return (
		<div>
			<MainHeader text={"Locks"} />
			<div
				style={{
					boxShadow: "0px 40px 60px 0px rgba(0, 0, 0, 0.25)",
				}}
				className=" tw-bg-white tw-w-full tw-min-h-screen tw-overflow-y-visible tw-mx-auto tw-p-5">
				<div className="tw-flex tw-justify-between tw-items-center tw-mb-3">
					{" "}
					<div className="">
						<h4 className="tw-text-base rowdies tw-font-normal tw-text-plain">
							Locks
						</h4>
						<SearchInput
							search={search}
							setSearch={setSearch}
							placeholder={"Search Locks'"}
						/>
					</div>
					<div className="tw-bg-white tw-p-2 tw-h-fit tw-flex tw-items-center tw-gap-x-2 tw-text-main tw-cursor-pointer tw-text-xl tw-z-50 tw-w-fit">
						<span
							className={`${
								display === "list" && "tw-bg-main tw-text-white"
							} tw-p-1 tw-rounded-md`}
							onClick={() => setDisplay("list")}>
							<RiMenuLine />
						</span>
						<span
							className={`${
								display === "grid" && "tw-bg-main tw-text-white"
							} tw-p-1 tw-rounded-md`}
							onClick={() => setDisplay("grid")}>
							<BsGridFill />
						</span>
					</div>
				</div>
				{display === "grid" && (
					<div className="tw-mt-10 tw-grid  md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-6 tw-w-full">
						{currentItems?.map((item, i) => (
							<div
								key={i}
								style={{
									boxShadow: "0px 30px 50px 0px rgba(2, 28, 47, 0.10)",
								}}
								className="tw-bg-white tw-pb-5 tw-relative tw-rounded-xl">
								<img
									src={item?.properties?.image_url}
									alt={item?.properties?.image_alt_text}
									className=""
								/>
								<div className="tw-px-4 ">
									<h6 className="tw-text-base rowdies tw-text-main tw-text-ellipsis tw-font-light tw-leading-7 tw-text-center">
										{item?.display_name}
									</h6>
									<p className="tw-text-[#354857] tw-font-light tw-text-center manrope tw-text-sm tw-text-ellipsis tw-leading-[22px] tw-capitalize">
										{item?.device_type?.replace(/[_]/g, " ")}
										{" | "}
										{item?.properties?.manufacturer?.replace(/[_]/g, " ")}
									</p>
								</div>
								<div className="tw-px-4">
									<p className="tw-text-[#354857] tw-font-light  manrope tw-text-sm tw-text-ellipsis tw-leading-[22px]">
										Door Status{": "}{" "}
										{item?.properties?.locked ? <>Locked</> : <>Unlocked</>}
									</p>
									<p className="tw-text-[#354857] tw-font-light  manrope tw-text-sm tw-text-ellipsis tw-leading-[22px] tw-capitalize">
										Compatibility{": "}
										{item?.capabilities_supported?.map((it, x) => (
											<span key={x}>{it?.replace(/[_]/g, " ")} | </span>
										))}
									</p>
									<p className="tw-text-[#354857] tw-font-light  manrope tw-text-sm tw-text-ellipsis tw-leading-[22px] tw-capitalize">
										Battery{": "}
										{item?.properties?.battery?.status}
									</p>
									{item?.properties?.keypad_battery && (
										<p className="tw-text-[#354857] tw-font-light  manrope tw-text-sm tw-text-ellipsis tw-leading-[22px]">
											Keypad Battery{": "}{" "}
											{item?.properties?.keypad_battery?.level}
										</p>
									)}
								</div>

								<div className="tw-flex tw-justify-center tw-mt-5 tw-gap-2 tw-items-center">
									{item?.capabilities_supported?.includes("lock") && (
										<span
											onClick={() => {
												setIsDeleted(item);
												setModal(item?.properties?.locked ? "unlock" : "lock");
											}}
											title={
												!item?.properties?.locked ? "Lock Door" : "Unlock Door"
											}
											className="tw-cursor-pointer">
											{item?.properties?.locked ? (
												<TfiUnlock size={30} color="#1dc4e9" />
											) : (
												<SlLock size={30} color="#1dc4e9" />
											)}
										</span>
									)}
									{item?.capabilities_supported?.includes("access_code") && (
										<span
											title="Manage Access Code"
											onClick={() => {
												setIsDeleted(item);
												setModal("access_code");
											}}
											className="tw-cursor-pointer">
											<PiPassword size={30} color="#1dc4e9" />
										</span>
									)}
								</div>
							</div>
						))}
					</div>
				)}
				{display === "list" && (
					<>
						<div className="tw-relative tw-overflow-x-auto tw-shadow-md sm:tw-rounded-lg">
							<table className="tw-w-full tw-text-sm tw-text-left tw-text-gray-500">
								<thead className="tw-text-md tw-text-white tw-capitalize tw-bg-secondary">
									<tr>
										<th scope="col" className="tw-p-4">
											<div className="tw-flex tw-items-center">
												<input
													id="checkbox-all-search"
													type="checkbox"
													className="tw-w-4 tw-h-4 tw-text-blue-600 tw-bg-gray-100 tw-border-gray-300 tw-rounded focus:tw-ring-blue-500 dark:focus:tw-ring-blue-600 dark:tw-ring-offset-gray-800 dark:focus:tw-ring-offset-gray-800 focus:tw-ring-2 dark:tw-bg-gray-700 dark:tw-border-gray-600"
												/>
												<label
													htmlFor="checkbox-all-search"
													className="tw-sr-only">
													checkbox
												</label>
											</div>
										</th>
										<th scope="col" className="tw-px-6 tw-py-3">
											Name
										</th>
										<th scope="col" className="tw-px-6 tw-py-3">
											Status
										</th>
										<th scope="col" className="tw-px-6 tw-py-3 tw-text-center">
											Connectivity
										</th>
										<th scope="col" className="tw-px-6 tw-py-3 tw-text-center">
											Power
										</th>
										<th scope="col" className="tw-px-6 tw-py-3 tw-text-center">
											Code
										</th>
									</tr>
								</thead>
								<tbody>
									{currentItems?.map((prod, i) => (
										<tr
											key={i}
											className="tw-bg-white tw-border-b hover:tw-bg-gray-50">
											<td className="tw-w-4 tw-p-4">
												<div className="tw-flex tw-items-center">
													<input
														id="checkbox-table-search-1"
														type="checkbox"
														className="tw-w-4 tw-h-4 tw-text-blue-600 tw-bg-gray-100 tw-border-gray-300 tw-rounded focus:tw-ring-blue-500 dark:focus:tw-ring-blue-600 dark:tw-ring-offset-gray-800 dark:focus:tw-ring-offset-gray-800 focus:tw-ring-2 dark:tw-bg-gray-700 dark:tw-border-gray-600"
													/>
													<label
														htmlFor="checkbox-table-search-1"
														className="tw-sr-only">
														checkbox
													</label>
												</div>
											</td>
											<th
												scope="row"
												className="tw-px-6 tw-py-4 tw-font-medium tw-text-gray-900 tw-whitespace-nowrap dark:tw-text-white tw-cursor-pointer">
												<div className="tw-flex tw-items-center tw-gap-2">
													<div className="tw-bg-[#E4FFEF] tw-size-11 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-p-1">
														<img
															src={prod?.properties?.image_url}
															alt={prod?.properties?.image_alt_text}
															className=""
														/>
													</div>
													<div className="">
														<h4 className="tw-text-base tw-font-bold tw-text-main rowdies">
															{prod?.display_name}
														</h4>
														<p className="tw-text-xm tw-text-secondary tw-capitalize satoshi">
															{prod?.device_type?.replace(/[_]/g, " ")}
															{" | "}
															{prod?.properties?.manufacturer?.replace(
																/[_]/g,
																" "
															)}
														</p>
													</div>
												</div>
											</th>
											<th
												scope="row"
												className="tw-px-6 tw-py-4 tw-font-medium tw-text-gray-900 tw-whitespace-nowrap dark:tw-text-white tw-cursor-pointer">
												<label
													className="tw-relative tw-inline-flex tw-items-center tw-cursor-pointer"
													onClick={() => {
														setIsDeleted(prod);
														setModal(
															prod?.properties?.locked ? "unlock" : "lock"
														);
													}}
													title={
														prod?.properties?.locked ? "Locked" : "Unlocked"
													}>
													<input
														type="checkbox"
														checked={prod?.properties?.locked}
														className="tw-sr-only tw-peer peer"
														// onChange={toggle}
													/>
													<div className="tw-w-14 tw-h-7 tw-bg-gray-200 peer-focus:tw-outline-none peer-focus:tw-ring-4 peer-focus:tw-ring-blue-300 dark:peer-focus:tw-ring-main tw-rounded-full peer dark:tw-bg-gray-700 peer-checked:after:tw-translate-x-full peer-checked:after:tw-border-white after:tw-content-[''] after:tw-absolute after:tw-top-0.5 after:tw-left-[4px] after:tw-bg-white after:tw-border-gray-300 after:tw-border after:tw-rounded-full after:tw-h-6 after:tw-w-6 after:tw-transition-all tw-border-gray-600 peer-checked:tw-bg-main"></div>
												</label>
											</th>
											<th
												scope="row"
												title={prod?.properties?.online ? "Good" : "Bad"}
												className="tw-px-6 tw-py-4 tw-font-medium tw-text-gray-900 tw-whitespace-nowrap dark:tw-text-white tw-cursor-pointer">
												<div className="tw-flex tw-justify-center">
													{prod?.properties?.online ? (
														<MdSignalWifi3Bar size={24} color="green" />
													) : (
														<MdSignalWifiBad size={24} color="red" />
													)}
												</div>
											</th>
											<th
												title={prod?.properties?.battery?.status}
												scope="row"
												className="tw-px-6 tw-py-4 tw-font-medium tw-text-gray-900 tw-whitespace-nowrap dark:tw-text-white tw-cursor-pointer tw-flex tw-justify-center">
												<div className="tw-flex tw-justify-center">
													<BatteryLevelCheck
														level={prod?.properties?.battery_level}
													/>
												</div>
											</th>
											<th
												scope="row"
												className="tw-px-6 tw-py-4 tw-font-medium tw-text-gray-900 tw-whitespace-nowrap dark:tw-text-white tw-cursor-pointer">
												<div className="tw-mt-3 tw-flex tw-justify-center tw-items-center tw-gap-3">
													{prod?.capabilities_supported?.includes(
														"access_code"
													) && (
														<span
															title="Manage Access Code"
															onClick={() => {
																setIsDeleted(prod);
																setModal("access_code");
															}}
															className="tw-cursor-pointer">
															<IoEllipsisVerticalOutline
																size={30}
																className="tw-text-main"
															/>
														</span>
													)}
												</div>
											</th>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</>
				)}
				{pageCount > 1 && (
					<MainPaginate
						pageCount={pageCount}
						handlePageClick={handlePageClick}
					/>
				)}
				{["lock", "unlock"]?.includes(modal) && (
					<SuspendRestoreModal
						handleClose={() => setModal("")}
						item={isDeleted}
						onClick={handleDelete}
						loading={loading}
						type={modal}
					/>
				)}
				{["access_code"]?.includes(modal) && (
					<AccessCodeModal
						handleClose={() => setModal("")}
						item={isDeleted}
						handleSubmit={handleDelete}
						loading={loading}
						type={modal}
						textChange={textChange}
						state={state}
					/>
				)}
			</div>
		</div>
	);
};

const Locks = () => <MainLocks />;

export default Locks;

const BatteryLevelCheck = ({ level }) => {
	let [percent, setPercent] = useState(0);
	useEffect(() => {
		if (level) {
			let newPercent = (level / 1) * 100;
			setPercent(Math.floor(newPercent));
		}
	}, [level]);

	return (
		<span className={``} title={`${percent}%`}>
			{percent < 20 ? (
				<MdBattery20 size={24} />
			) : percent < 30 ? (
				<MdBattery30 size={24} />
			) : percent < 50 ? (
				<MdBattery50 size={24} />
			) : percent < 60 ? (
				<MdBattery60 size={24} />
			) : percent < 80 ? (
				<MdBattery80 size={24} />
			) : percent < 90 ? (
				<MdBattery90 size={24} />
			) : (
				<MdBatteryFull size={24} />
			)}
		</span>
	);
};