/* eslint-disable react/prop-types */
import "./loader.css";

const ModalContainer = ({ children }) => {
	return (
		<div className="tw-fixed tw-inset-0 tw-w-full tw-h-screen tw-flex tw-justify-center tw-items-center tw-bg-white tw-bg-opacity-10 tw-backdrop-blur-s,">
			{children}
		</div>
	);
};

export default ModalContainer;

export const ModalComponents = ({
	show,
	title,
	close,
	children,
	width,
	subtitle,
}) => {
	return (
		<div
			className={`tw-fixed ${
				show ? "tw-flex" : "tw-hidden"
			} tw-flex tw-items-center tw-top-0 tw-left-0 tw-right-0 tw-z-50  tw-w-full tw-p-4 tw-overflow-x-hidden tw-overflow-y-auto md:tw-inset-0 h-[calc(100%-1rem)] tw-max-h-full tw-scrollbar-hide`}>
			<div className="tw-fixed tw-inset-0 tw-bg-black tw-opacity-30"></div>
			<div
				className={`tw-relative tw-w-full ${
					width ? width : "tw-max-w-2xl"
				} tw-mx-auto tw-max-h-full`}>
				{/* <!-- Modal content --> */}
				<div className="tw-relative tw-bg-white tw-rounded-sm tw-shadow">
					{/* <!-- Modal header --> */}
					<div className="tw-flex tw-items-center tw-justify-between tw-gap-4 tw-p-4 tw-py-2 tw-rounded-t">
						<div>
							<h3 className="tw-text-xl tw-font-semibold tw-text-gray-900">
								{title}
							</h3>
							<p className="tw-text-xs tw-text-gray-400 tw-mt-1 tw-text">
								{subtitle}
							</p>
						</div>
						<button
							type="button"
							className="tw-bg-gray-400 tw-text-white tw-rounded-lg tw-text-sm tw-p-1.5 tw-inline-flex tw-items-center"
							onClick={close}>
							<span className="sr-only">Close modal</span>
							<svg
								aria-hidden="true"
								className="w-3 h-3"
								fill="currentColor"
								viewBox="0 0 20 20"
								xmlns="http://www.w3.org/2000/svg">
								<path
									fillRule="evenodd"
									d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
									clipRule="evenodd"></path>
							</svg>
						</button>
					</div>
					<hr className="tw-m-0 tw-border-1 tw-border-primary" />
					{/* <!-- Modal body --> */}
					<div className="tw-p-6 tw-space-y-6">{children}</div>
				</div>
			</div>
		</div>
	);
};

export const PageLoader = () => {
	return (
		<div className="ring">
			Loading
			<span className="spanClass"></span>
		</div>
	);
};
