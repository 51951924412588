/* eslint-disable react/prop-types */
import Gif from "../../assets/deleting.gif";
import { PrimaryBtn } from "../button";
import ModalContainer from "../button/modalcontainer";

const DeleteModal = ({ item, onClick, loading, cancel, handleClose }) => {
	return (
		<div>
			<ModalContainer>
				<div className="tw-h-72 lg:tw-w-96 tw-bg-white tw-p-6 tw-rounded-lg tw-shadow-md">
					<img src={Gif} alt="" className="tw-mx-auto" />
					<h6 className="tw-text-center tw-text-black tw-pt-6 manrope tw-font-bold tw-text-sm">
						Are you sure you want to delete “{item?.firstName} {item?.lastName}
						”.
						<br /> Action cannot be undone
					</h6>
					<div className="tw-flex tw-justify-center tw-gap-5 tw-mt-4">
						<button
							onClick={cancel ? cancel : handleClose ? handleClose : null}
							className="tw-rounded-[10px] tw-border-myblue tw-border tw-h-10 tw-w-40 tw-bg-white tw-text-myblue tw-font-semibold manrope tw-text-sm">
							Cancel
						</button>
						<PrimaryBtn
							onClick={onClick}
							loading={loading}
							text={"Confirm"}
							width={
								"tw-bg-main tw-w-40 tw-h-10 tw-rounded-xl tw-font-medium tw-text-base tw-text-white"
							}
						/>
						{/* <Mybtn onClick={() => setModal("done")} text={"Confirm"} /> */}
					</div>
				</div>
			</ModalContainer>
		</div>
	);
};

export const SuspendRestoreModal = ({
	item,
	onClick,
	loading,
	cancel,
	handleClose,
	type,
}) => {
	return (
		<div>
			<ModalContainer>
				<div className="tw-h-72 lg:tw-w-96 tw-bg-white tw-p-6 tw-rounded-lg tw-shadow-md">
					<img src={Gif} alt="" className="tw-mx-auto" />
					<h6 className="tw-text-center tw-text-black tw-pt-6 manrope tw-font-bold tw-text-sm">
						Are you sure you want to {type} “ {item?.firstName} {item?.lastName}{" "}
						{item?.display_name} ”.
						<br />{" "}
						{type === "disable" ? (
							<>User access to the system will be restricted</>
						) : type === "lock" ? (
							<>Access to the door will be restricted</>
						) : type === "unlock" ? (
							<>Access to the door will be unrestricted</>
						) : (
							<>User will have access to the system</>
						)}
					</h6>
					<div className="tw-flex tw-justify-center tw-gap-5 tw-mt-4">
						<button
							onClick={cancel ? cancel : handleClose ? handleClose : null}
							className="tw-rounded-[10px] tw-border-myblue tw-border tw-h-10 tw-w-40 tw-bg-white tw-text-myblue tw-font-semibold manrope tw-text-sm">
							Cancel
						</button>
						<PrimaryBtn
							onClick={onClick}
							loading={loading}
							text={"Confirm"}
							width={
								"tw-bg-main tw-w-40 tw-h-10 tw-rounded-xl tw-font-medium tw-text-base tw-text-white"
							}
						/>
						{/* <Mybtn onClick={() => setModal("done")} text={"Confirm"} /> */}
					</div>
				</div>
			</ModalContainer>
		</div>
	);
};

export default DeleteModal;
