import { useContext, useState, useEffect } from "react";
import { GlobalState } from "../data/Context";
import { BsGithub, BsTwitterX } from "react-icons/bs";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

const AppHeader = () => {
	let { nav, toggleNav } = useContext(GlobalState),
		[isShadow, setIsShadow] = useState(false),
		location = useLocation(),
		handleScroll = () => {
			window.onscroll = () => {
				if (window.scrollY > 100) setIsShadow(true);
				else setIsShadow(false);
			};
		};

	const navSlide = {
		hidden: {
			y: -1000,
		},
		show: {
			y: 0,
			transiton: {
				ease: "easeInOut",
				duration: 2,
			},
		},
		exit: {
			y: -1000,
			transition: { ease: "easeInOut", duration: 1 },
		},
	};

	useEffect(() => {
		handleScroll();
	}, []);

	return (
		<nav
			className={`tw-sticky tw-top-[-3px] tw-left-0 tw-right-0 tw-z-20 ${
				nav
					? "tw-bg-main"
					: location?.pathname === "/dilaac" && window.screenY < 100
					? "tw-bg-[#FBA222]"
					: location?.pathname === "/platview" && window.screenY < 100
					? "tw-bg-[#262163]"
					: location?.pathname === "/healthpay" && window.screenY < 100
					? "tw-bg-[#0FD6BE]"
					: "tw-bg-transparent"
			} tw-py-5  tw-px-8 tw-h-20 md:tw-px-16 lg:tw-px-28 tw-flex tw-items-center tw-justify-center ${
				isShadow && !nav ? "tw-shadow glasslike" : ""
			}`}>
			<div
				className={`tw-max-w-md tw-w-[300px] tw-h-12 ${
					nav ? "tw-bg-white" : "tw-bg-main"
				} tw-rounded-full tw-flex tw-items-center tw-px-4 tw-justify-between`}>
				<p className="tw-my-0">
					<RouterLink
						to="/"
						className={`${
							!nav ? "tw-text-white" : "tw-text-main"
						} focus:tw-outline-none tw-capitalize tw-ease-in-out tw-cursor-pointer rowdies tw-no-underline`}>
						Ifeoluwa Adaralegbe
					</RouterLink>
				</p>
				<span
					onClick={toggleNav}
					className={`${
						nav ? "tw-text-white tw-bg-main" : "tw-text-main tw-bg-white"
					} focus:tw-outline-none tw-capitalize tw-py-1 tw-px-4 tw-rounded-full tw-ease-in-out tw-cursor-pointer rowdies`}>
					{nav ? "close" : "menu"}
				</span>
			</div>
			<AnimatePresence>
				{nav && (
					<motion.div
						className={`tw-absolute tw-top-20 tw-left-0 tw-w-full tw-bg-main tw-p-4 tw-space-y-8 tw-overflow-x-hidden tw-h-screen tw-flex tw-flex-col tw-justify-evenly tw-py-10 tw-transition-all tw-ease-out tw-duration-500 tw-z-50`}
						variants={navSlide}
						initial="hidden"
						animate="show"
						exit="exit">
						<RouterLink
							to="https://github.com/ifeoluwabk4ever"
							target="_blank"
							onClick={toggleNav}
							className="tw-text-white tw-text-center tw-block tw-cursor-pointer tw-no-underline tw-transition-transform hover:tw-scale-110 tw-duration-300 tw-ease-in manrope tw-text-2xl md:tw-text-3xl">
							My Project
						</RouterLink>
						<RouterLink
							to="/about"
							onClick={toggleNav}
							className="tw-text-white tw-text-center tw-block tw-cursor-pointer tw-no-underline tw-transition-transform hover:tw-scale-110 tw-duration-300 tw-ease-in manrope tw-text-2xl md:tw-text-3xl">
							About Me
						</RouterLink>
						<a
							href="mailto:ifeoluwabk4ever@gmail.com"
							onClick={toggleNav}
							className="tw-text-white tw-text-center tw-block tw-cursor-pointer tw-transition-transform hover:tw-scale-110 tw-duration-300 tw-ease-in tw-underline manrope tw-text-2xl md:tw-text-3xl">
							Contact Me
						</a>
					</motion.div>
				)}
			</AnimatePresence>
		</nav>
	);
};

export default AppHeader;

export let socials = [
	{
		icon: <BsGithub className="md:text-xl" />,
		url: "https://github.com/ifeoluwabk4ever",
		title: "Github",
	},
	{
		icon: <BsTwitterX className="md:text-xl" />,
		url: "https://twitter.com/ifeoluwa2707",
		title: "Twitter",
	},
];

export const Footer = () => {
	return (
		<div className="tw-w-full md:tw-p-10 tw-p-3">
			<div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-10">
				<div className=" tw-md:w-3/4">
					<h1 className="tw-font-bold rowdies tw-text-3xl md:tw-text-5xl tw-mb-3">
						Let&apos;s talk
					</h1>
					<p className="tw-text-base tw-font-medium tw-leading-6 manrope tw-text-secondary">
						Do you have a project you want to work on? I would love to hear you
						out. Get in touch with me at{" "}
						<a href="mailto:ifeoluwabk4ever@gmail.com" className="font-bold">
							ifeoluwabk4ever@gmail.com
						</a>
						, and let&apos;s bring that amazing idea to life.
					</p>
				</div>
				<div className="md:tw-mx-auto">
					<ul>
						<li className="">
							<RouterLink
								to="https://github.com/ifeoluwabk4ever"
								className="tw-font-thin tw-py-3 tw-block tw-cursor-pointer tw-no-underline tw-transition-transform hover:tw-scale-110 tw-duration-300 tw-ease-in manrope tw-text-md md:tw-text-xl tw-text-secondary">
								My Project
							</RouterLink>
						</li>
						<li className="">
							<RouterLink
								to="/about"
								className="tw-font-thin tw-py-3 tw-block tw-cursor-pointer tw-no-underline tw-transition-transform hover:tw-scale-110 tw-duration-300 tw-ease-in manrope tw-text-md md:tw-text-xl tw-text-secondary">
								About me
							</RouterLink>
						</li>
					</ul>
				</div>
			</div>
			<div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-10 md:tw-pt-10 tw-py-3">
				<p className="tw-text-base tw-font-medium tw-leading-6 manrope tw-text-secondary">
					Copyright &copy; Ifeoluwa Adaralegbe {new Date().getFullYear()}
				</p>
				<div className="tw-order-0 md:tw-order-1 tw-w-3/5 md:tw-ms-auto md:tw-me-6">
					<ul className="tw-flex tw-gap-2 tw-border-0 tw-justify-evenly tw-items-center">
						{socials.map((item, index) => (
							<li
								key={index}
								className="tw-list-group-item tw-border-0 tw-bg-transparent tw-text-main tw-flex tw-items-center">
								<a
									className="tw-text-main tw-text-decoration-none fontReduce"
									target={"_blank"}
									rel="noreferrer"
									href={item?.url || "#"}>
									{item?.icon}
								</a>
							</li>
						))}
					</ul>
				</div>
			</div>
		</div>
	);
};
