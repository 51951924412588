/* eslint-disable react/prop-types */
import { useNavigate } from "react-router-dom";
import { PrimaryBtn } from "../../components/button";
import Myinput, { PasswordInput } from "../../components/inputs/myinput";
import "./login.css";
import { SlLock } from "react-icons/sl";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useForm, Controller, FormProvider } from "react-hook-form";
import useAuthStore, { apiCall } from "../../data/stores/authstore";
import useGenFetcher from "../../data/useGenFetcher";
import useErrorStore from "../../data/stores/errorstore";

const Login = () => {
	const { isAuth, login } = useAuthStore(),
		{ loadUser } = useGenFetcher(),
		{ returnErrors } = useErrorStore(),
		navigate = useNavigate(),
		[loading, setLoading] = useState(false);

	const methods = useForm({
		defaultValues: {
			email: "",
			password: "",
		},
	});

	useEffect(() => {
		if (isAuth) {
			navigate("/");
		}
	});
	const handleSubmit = async data => {
		// setLoading(true);
		// const token = "zeno-auth-token";
		// localStorage.setItem("token", token);
		// login(token);
		// setLoading(false);

		if (!data?.email || !data?.password) return;
		setLoading(true);
		let { response, errArr, errMsg } = await apiCall(
			"post",
			`/api/v1/user/login`,
			data
		);
		// console.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			login(response);
			loadUser();
			navigate("/");
			return;
		}
		setLoading(false);
	};

	return (
		<>
			<AuthTemplate title={"Login"} icon={<SlLock size={30} color="#1dc4e9" />}>
				<FormProvider {...methods}>
					<form onSubmit={methods.handleSubmit(handleSubmit)}>
						<div className="tw-space-y-4">
							{/* Email */}
							<Controller
								name="email"
								control={methods.control}
								rules={{
									required: "This field is required",
									pattern: {
										value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/g,
										message: "You must enter a valid email address",
									},
								}}
								render={({ field: { onChange, name } }) => (
									<fieldset className="">
										<Myinput
											label={"Email"}
											type={"email"}
											onChange={onChange}
											name={name}
										/>
										{methods.formState.errors.email && (
											<span className="tw-text-xs tw-font-bold tw-text-red-600 tw-block">
												{methods.formState.errors.email.message}
											</span>
										)}
									</fieldset>
								)}
							/>

							{/* Password*/}
							<Controller
								name="password"
								control={methods.control}
								rules={{
									required: "This field is required",
									pattern: {
										value:
											/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\s]).{8,}$/g,
										message:
											"Your password must have at least one uppercase letter, one lowercase letter, one digit, one special character, and a minimum length of 8 characters.",
									},
								}}
								render={({ field: { onChange, name } }) => (
									<fieldset className="">
										<div>
											<PasswordInput
												label={"Password"}
												// value={state?.password}
												name={name}
												onChange={onChange}
											/>
											{methods.formState.errors.password && (
												<span className="tw-text-xs tw-font-bold tw-text-red-600">
													{methods.formState.errors.password.message}
												</span>
											)}
										</div>
									</fieldset>
								)}
							/>
							<div className="tw-flex tw-justify-between tw-items-center tw-pt-5">
								<div className="tw-flex tw-items-center tw-gap-2">
									<input
										type="checkbox"
										name=""
										className="tw-size-4 tw-rounded-sm"
										id=""
									/>
									<small className="tw-text-sm tw-font-normal tw-text-scgreen">
										Remember Me
									</small>
								</div>
								<small
									onClick={() => navigate("/forgotpassword")}
									className="tw-text-sm tw-cursor-pointer tw-font-normal tw-text-scgreen">
									Forgot Password?
								</small>
							</div>
							<small
								onClick={() => navigate("/activate")}
								className="tw-text-sm tw-cursor-pointer tw-font-normal tw-text-scgreen tw-py-3 tw-justify-end tw-flex">
								Activate here?
							</small>
						</div>
						<div className="tw-mt-12">
							{/* <button className="tw-bg-scblue tw-w-full tw-h-12 tw-rounded-xl tw-font-medium tw-text-base tw-text-white">
								Login
							</button> */}
							<PrimaryBtn
								onClick={handleSubmit}
								loading={loading}
								text={"Login"}
								width={
									"tw-bg-main tw-w-full tw-h-12 tw-rounded-xl tw-font-medium tw-text-base tw-text-white"
								}
							/>
						</div>
					</form>
				</FormProvider>
			</AuthTemplate>
		</>
	);
};

export default Login;

export const AuthTemplate = ({ title, children, icon }) => {
	return (
		<>
			<div className="auth-wrapper">
				<div className="auth-content">
					<div className="auth-bg">
						<span className="r"></span>
						<span className="r s"></span>
						<span className="r s"></span>
						<span className="r"></span>
					</div>
					<div className="card tw-py-10 tw-px-6">
						{icon && (
							<div className="tw-flex tw-justify-center tw-pb-4">{icon}</div>
						)}
						{title && (
							<h6 className="tw-text-center tw-font-normal tw-text-black rowdies tw-text-xl tw-uppercase">
								{title}
							</h6>
						)}
						{children}
					</div>
				</div>
			</div>
		</>
	);
};
