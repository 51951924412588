import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";
import { DeleteData, EditData } from "./authstore";

const UserStore = set => ({
	data: null,
	status: "",
	clients: null,
	getSearchUser: payload => {
		set(
			state => ({
				mainSearch:
					payload?.search === state?.search
						? payload?.data || payload
						: state?.mainSearch,
				isFound: true,
			}),
			false,
			"getSearchUser"
		);
	},
	getSearch: payload => {
		set({ search: payload?.data || payload }, false, "getSearch");
	},
	resetUserSearch: () => {
		set({ search: "", mainSearch: null, isFound: null }, false, "getSearch");
	},
	getUsers: payload => {
		set({ data: payload?.data || payload }, false, "getUsers");
	},
	addUser: payload => {
		let data = payload?.data || payload;

		set(
			state => ({
				data: {
					...state?.data,
					// eslint-disable-next-line no-unsafe-optional-chaining
					docs: state?.data?.docs ? [data, ...state?.data?.docs] : [data],
					totalDocs: state?.data?.totalDocs ? 1 + state?.data?.totalDocs : 1,
					docsTotal: state?.data?.docsTotal ? 1 + state?.data?.docsTotal : 1,
				},
				status: "added",
			}),
			false,
			"addUser"
		);
	},
	deleteUser: payload => {
		let data = payload?.data || payload;
		set(
			state => ({
				data: {
					...state?.data,
					docs: DeleteData(state?.data?.docs, data),
					totalDocs: state?.data?.totalDocs ? state?.data?.totalDocs - 1 : 0,
					docsTotal: state?.data?.docsTotal ? state?.data?.docsTotal - 1 : 0,
				},
				status: "deleted",
			}),
			false,
			"deleteUser"
		);
	},
	updateUser: payload => {
		let data = payload?.data || payload;
		set(
			state => ({
				data: {
					...state?.data,
					docs: EditData(state?.data?.docs, data),
				},
				status: "updated",
			}),
			false,
			"editUser"
		);
	},
	usersFail: () => {
		set({ status: "", isFound: null });
	},
	logoutUser: () => {
		set({ status: "", isFound: null, data: null, mainSearch: null });
	},
});

const useUserStore = create(
	devtools(
		persist(UserStore, {
			name: "users",
		})
	)
);

export default useUserStore;
