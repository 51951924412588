import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";

const ActivityStore = set => ({
	data: null,
	status: "",
	getSearchActivity: payload => {
		set(
			state => ({
				mainSearch:
					payload?.search === state?.search
						? payload?.data || payload
						: state?.mainSearch,
				isFound: true,
			}),
			false,
			"getSearchActivity"
		);
	},
	getSearch: payload => {
		set({ search: payload?.data || payload }, false, "getSearch");
	},
	resetActivitySearch: () => {
		set({ search: "", mainSearch: null, isFound: null }, false, "getSearch");
	},
	getActivity: payload => {
		set({ data: payload?.data || payload }, false, "getActivity");
	},
	activityFail: () => {
		set({ status: "", isFound: null });
	},
	logoutActivity: () => {
		set({ status: "", isFound: null, data: null, mainSearch: null });
	},
});

const useActivityStore = create(
	devtools(
		persist(ActivityStore, {
			name: "activity",
		})
	)
);

export default useActivityStore;
