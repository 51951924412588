import { useContext } from "react";
import useLockStore from "../../data/stores/locksstore";
import { GlobalState } from "../../data/Context";
import MainHeader from "../../components/mainheader";
import { SiSecurityscorecard } from "react-icons/si";
import { TfiUnlock } from "react-icons/tfi";
import { SlLock } from "react-icons/sl";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../../data/stores/authstore";
import { UsersList } from "../management";
import { ActivtiesList } from "../activity";
import useUserStore from "../../data/stores/userstore";
import useActivityStore from "../../data/stores/activitystore";

export const MainDashboard = () => {
	let { data } = useLockStore(),
		{ numberWithCommas } = useContext(GlobalState),
		navigate = useNavigate(),
		{ user } = useAuthStore(),
		users = useUserStore(),
		activity = useActivityStore();

	let Arr = [
		{
			icon: <SiSecurityscorecard className="" size={24} />,
			title: "Total Locks",
			value: `${numberWithCommas(data?.length || 0)}`,
			onclick: () => navigate("/locks"),
		},
		{
			icon: <SlLock size={24} />,
			title: "Locked",
			value: numberWithCommas(
				data?.filter(it => it?.properties?.locked)?.length || 0
			),
			onclick: () => navigate("/locks"),
		},
		{
			icon: <TfiUnlock size={24} />,
			title: "Unlocked",
			value: ` ${numberWithCommas(
				data?.filter(it => !it?.properties?.locked)?.length || 0
			)}`,
			onclick: () => navigate("/locks"),
		},
	];

	return (
		<>
			<MainHeader text={"Dashboard"} />
			<div className="tw-container lg:tw-px-10 tw-min-h3/4">
				<div className="tw-w-full">
					<div className=" tw-w-full tw-pt-10">
						<div className="container tw-grid  md:tw-grid-cols-2 lg:tw-grid-cols-3 -tw-z-[1000] tw-gap-10">
							{Arr?.map((it, id) => (
								<div
									key={id}
									className="tw-bg-white tw-z-10 tw-rounded-xl tw-h-44 tw-p-6 tw-cursor-pointer"
									onClick={it?.onclick}>
									<div className="tw-bg-[#E4FFEF] tw-size-11 tw-rounded-full tw-flex tw-justify-center tw-items-center">
										{it?.icon}
									</div>
									<div className="tw-mt-6">
										<h4 className="tw-text-base tw-font-medium tw-text-secondary satoshi">
											{it?.title}
										</h4>
										<strong className="tw-text-xm bolder tw-font-bold tw-text-secondary">
											{it?.value}
										</strong>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
				<div className="tw-pt-12">
					{user?.privilege === "superadmin" ? (
						<>
							<button
								onClick={() => navigate("/management")}
								className="tw-text-plain tw-text-xl tw-font-normal tw-capitalize rowdies tw-p-2 tw-rounded-lg hover:tw-bg-gray-100 tw-mb-2">
								All Users
							</button>
							<UsersList datum={users?.data} homepage />
						</>
					) : (
						<>
							<button
								onClick={() => navigate("/activity")}
								className="tw-text-plain tw-text-xl tw-font-normal tw-capitalize rowdies tw-p-2 tw-rounded-lg hover:tw-bg-gray-100 tw-mb-2">
								Activities
							</button>
							<ActivtiesList datum={activity?.data} homepage />
						</>
					)}
				</div>
			</div>
		</>
	);
};

const Dashboard = () => <MainDashboard />;

export default Dashboard;
