/* eslint-disable react/prop-types */
import { createContext } from "react";
import { useState } from "react";
import { RxDashboard } from "react-icons/rx";
import {
	// TbNotification,
	TbLogs,
} from "react-icons/tb";
// import { VscSettings } from "react-icons/vsc";
// import { HiOutlineUser } from "react-icons/hi";
import { SiSecurityscorecard } from "react-icons/si";
import { FaUsers } from "react-icons/fa6";
import useAuthStore from "./stores/authstore";

export const GlobalState = createContext();

const DataProvider = ({ children }) => {
	const [nav, setNav] = useState(false);
	const handleCapitalize = word => {
		const splitter = word.trim().split(" ");
		const firstCap = splitter[0].split("");
		const remaining = splitter.slice(1, splitter.length).join(" ");

		const firstCapOne = firstCap[0].toUpperCase();
		const firstCapTwo = firstCap.slice(1, firstCap.length).join("");

		const joinFirst = `${firstCapOne}${firstCapTwo}`;

		return `${joinFirst} ${remaining}`;
	};

	const numberWithCommas = x => {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	};

	const nairaSignNeutral = "₦";
	const nairaSign = <span className="fontInherit">&#8358;</span>;

	const toggleNav = () => {
		setNav(!nav);
	};

	const { user } = useAuthStore();

	let sidebarList = [
		{
			name: "Dashboard",
			url: "/dashboard",
			icon: <RxDashboard className="" />,
			show: true,
		},
		{
			name: "Locks",
			url: "/locks",
			icon: <SiSecurityscorecard className="" />,
			show: true,
		},
		{
			name: "Activity Logs",
			url: "/activity",
			icon: <TbLogs className="" />,
			show: true,
		},
		{
			name: "Users",
			url: "/management",
			icon: <FaUsers className="" />,
			show: user?.privilege === "superadmin",
		},
		// {
		// 	name: "notifications",
		// 	url: "/notifications",
		// 	icon: <TbNotification className="" />,
		// },
		// {
		// 	name: "account profile",
		// 	url: "/profile",
		// 	icon: <HiOutlineUser className="" />,
		// },
		// {
		// 	name: "settings",
		// 	url: "/settings",
		// 	icon: <VscSettings className="" />,
		// },
	];

	const state = {
		handleCapitalize,

		numberWithCommas,

		nav,
		toggleNav,
		nairaSignNeutral,
		nairaSign,
		sidebarList: sidebarList?.filter(it => it?.show),
	};

	return <GlobalState.Provider value={state}>{children}</GlobalState.Provider>;
};

export default DataProvider;
