/* eslint-disable react/prop-types */
import { useContext } from "react";
import { GlobalState } from "../../data/Context";
import Logo from "../logo";
import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";
import useAuthStore from "../../data/stores/authstore";
import LogoutIcon from "../../assets/logout.svg";

const Sidebar = ({ children }) => {
	const { nav, toggleNav, sidebarList } = useContext(GlobalState),
		{ isAuth, logout } = useAuthStore();
	const navigate = useNavigate(),
		location = useLocation();
	const currentPath = location.pathname;
	const handleLogout = () => {
		logout();
		navigate("/");
	};

	return (
		<section>
			{isAuth && (
				<>
					<button
						type="button"
						className="tw-inline-flex tw-items-center tw-p-2 tw-mt-2 tw-ml-3 tw-text-sm tw-text-gray-500 tw-rounded-lg sm:tw-hidden hover:tw-bg-alternate focus:tw-outline-none tw-overflow-visible focus:tw-ring-2 focus:tw-ring-gray-200"
						onClick={toggleNav}>
						<span className="tw-sr-only">Open sidebar</span>

						<AiOutlineMenu size={20} />
					</button>
					<div
						className={`tw-fixed md:tw-hidden tw-top-0 tw-left-0 tw-z-40 tw-w-full tw-h-screen tw-transition-transform tw-bg-black tw-opacity-10 ${
							nav
								? "tw-translate-x-0"
								: "-tw-translate-x-full md:tw-translate-x-0"
						}`}
						onClick={toggleNav}></div>
					<aside
						className={`tw-fixed tw-top-0 tw-left-0 tw-z-40 tw-w-56 tw-bg-[#021c2f] tw-h-screen tw-pb-8 tw-transition-transform ${
							nav ? "translate-x-0" : "-tw-translate-x-full md:tw-translate-x-0"
						}`}>
						<div className="tw-h-screen tw-relative tw-py-4 tw-overflow-y-scroll noScroll">
							<div className="tw-flex tw-px-4 tw-justify-center tw-mb-6 tw-mt-4">
								<Logo height={"150px"} />
							</div>
							<div className="tw-pl-4">
								<div>
									<NavButton text={"pages"} />
									<div className="tw">
										{sidebarList?.map((item, i) => (
											<div
												onClick={() => {
													navigate(item?.url);
												}}
												key={i}
												className={`tw-flex tw-cursor-pointer tw-h-10 tw-items-center tw-my-1 tw-gap-4 tw-w-5/6 tw-rounded-lg hover:tw-bg-[#ebfff3] hover:tw-transition-all hover:tw-duration-300 hover:tw-text-[#021c2f] hover:tw-pl-5 ${
													currentPath === item?.url ||
													currentPath.includes(item?.url)
														? "tw-bg-[#ebfff3] tw-pl-5 tw-transition-all tw-duration-300"
														: "tw-text-alternate"
												}`}>
												{item?.icon}
												<h6 className="tw-text-sm tw-pt-2 manrope tw-font-medium tw-text-alternate tw-capitalize">
													{item?.name}
												</h6>
											</div>
										))}
									</div>
								</div>
							</div>
							<div className="tw-mt-8 tw-flex tw-px-4 tw-gap-4 tw-items-center tw-w-full hover:tw-bg-alternate">
								<img src={LogoutIcon} alt="" className="" />
								<button
									onClick={handleLogout}
									className="tw-text-white tw-text-base tw-capitalize rowdies tw-font-medium tw-p-2 tw-rounded-lg tw-w-full">
									Log Out
								</button>
							</div>
						</div>
					</aside>
				</>
			)}
			<div
				className={`${
					isAuth ? "sm:tw-ml-56 tw-bg-[#f7f7f7] tw-min-h-screen" : ""
				}`}>
				<div className={`${isAuth ? "tw-bg-[#f7f7f7] tw-min-h-screen" : ""}`}>
					{children}
				</div>
			</div>
		</section>
	);
};

const NavButton = ({ text }) => {
	return (
		<div>
			<button className="tw-text-white tw-text-sm tw-capitalize rowdies tw-font-light tw-p-2 tw-rounded-lg hover:tw-bg-alternate">
				{text}
			</button>
		</div>
	);
};
export default Sidebar;
