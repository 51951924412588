import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";
import { EditData2 } from "./authstore";

const LockStore = set => ({
	data: null,
	status: "",
	clients: null,
	getSearchLock: payload => {
		set(
			state => ({
				mainSearch:
					payload?.search === state?.search
						? payload?.data || payload
						: state?.mainSearch,
				isFound: true,
			}),
			false,
			"getSearchLock"
		);
	},
	getSearch: payload => {
		set({ search: payload?.data || payload }, false, "getSearch");
	},
	resetLockSearch: () => {
		set({ search: "", mainSearch: null, isFound: null }, false, "getSearch");
	},
	getLocks: payload => {
		set({ data: payload?.data || payload }, false, "getLocks");
	},
	updateLock: payload => {
		let data = payload?.data || payload;
		set(
			state => ({
				data: EditData2(state?.data, data),
				status: "updated",
			}),
			false,
			"editLock"
		);
	},
	locksFail: () => {
		set({ status: "", isFound: null });
	},
	logoutLock: () => {
		set({ status: "", isFound: null, data: null, mainSearch: null });
	},
});

const useLockStore = create(
	devtools(
		persist(LockStore, {
			name: "locks",
		})
	)
);

export default useLockStore;
