/* eslint-disable react/prop-types */
import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import MyInput, { SelectInput } from "../inputs/myinput";
import useErrorStore from "../../data/stores/errorstore";
import { toast } from "react-toastify";
import { apiCall } from "../../data/stores/authstore";
import ModalContainer from "../button/modalcontainer";
import useUserStore from "../../data/stores/userstore";
import { PrimaryBtn } from "../button";

const CreateUser = ({ handleClose }) => {
	const init = {},
		[state, setState] = useState(init),
		textChange = e => {
			let { name, value } = e.target;
			setState({ ...state, [name]: value });
		},
		[loading, setLoading] = useState(null),
		{ returnErrors } = useErrorStore(),
		{ addUser } = useUserStore();
	let handleSubmit = async e => {
		e?.preventDefault();
		if (!state?.firstName) return toast.info("Category title is required");
		setLoading(true);
		let { response, errArr, errMsg } = await apiCall(
			"post",
			"/api/v1/user/manage-users",
			{ ...state, privilege: "admin" }
		);
		// console.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			addUser(response);
			handleClose();
			return;
		}
		setLoading(false);
	};

	return (
		<div>
			<ModalContainer>
				<div className="lg:tw-w-96 tw-bg-white tw-p-5 tw-rounded-lg tw-shadow-md">
					<AiOutlineClose onClick={handleClose} className="tw-cursor-pointer" />
					<div className="tw-mt-5">
						<h6 className="tw-text-center tw-font-normal tw-text-black rowdies tw-text-xl">
							CREATE NEW USER
						</h6>
						<form onSubmit={handleSubmit} className="tw-mt-4">
							<div className="tw-space-y-3">
								<MyInput
									label={"First Name"}
									placeholder={"Enter First Name"}
									type={"text"}
									value={state?.firstName}
									onChange={textChange}
									name="firstName"
								/>
								<MyInput
									label={"Last Name"}
									placeholder={"Enter Last Name"}
									type={"text"}
									value={state?.lastName}
									onChange={textChange}
									name="lastName"
								/>
								<MyInput
									label={"Email"}
									placeholder={"Enter Email"}
									type={"email"}
									value={state?.email}
									onChange={textChange}
									name="email"
								/>
							</div>

							<div className=" tw-mt-4">
								<PrimaryBtn
									onClick={handleSubmit}
									loading={loading}
									text={"Create"}
									width={
										"tw-bg-main tw-w-full tw-h-12 tw-rounded-xl tw-font-medium tw-text-base tw-text-white"
									}
								/>
							</div>
						</form>
					</div>
				</div>
			</ModalContainer>
		</div>
	);
};

export default CreateUser;

export const AccessCodeModal = ({
	handleClose,
	loading,
	handleSubmit,
	item,
	state,
	textChange,
}) => {
	return (
		<div>
			<ModalContainer>
				<div className="lg:tw-w-96 tw-bg-white tw-p-5 tw-rounded-lg tw-shadow-md">
					<AiOutlineClose onClick={handleClose} className="tw-cursor-pointer" />
					<div className="tw-mt-5">
						<h6 className="tw-text-center tw-font-normal tw-text-black rowdies tw-text-xl">
							Update {item?.display_name} Access Code
						</h6>
						<form onSubmit={handleSubmit} className="tw-mt-4">
							<div className="tw-space-y-3">
								<MyInput
									label={"Access Code"}
									placeholder={"Enter Access Code"}
									type={"text"}
									value={state?.code}
									onChange={textChange}
									name="code"
								/>
								<SelectInput
									label={"Access Code Type"}
									placeholder={"Enter Access Code"}
									type={"text"}
									value={state?.type}
									onChange={textChange}
									name="type"
									options={[
										{ _id: "", title: "Select Access Code Type" },
										{
											_id: "ongoing",
											title: "Ongoing",
										},
										{
											_id: "timebound",
											title: "Time Bound",
										},
									]}
								/>
								{state?.type === "timebound" && (
									<>
										<MyInput
											label={"Start Time"}
											placeholder={"Enter Start Time"}
											type={"datetime-local"}
											value={state?.starts_at}
											onChange={textChange}
											name="starts_at"
										/>
										<MyInput
											label={"End Time"}
											placeholder={"Enter End Time"}
											type={"datetime-local"}
											value={state?.ends_at}
											onChange={textChange}
											name="ends_at"
										/>
									</>
								)}
							</div>

							<div className=" tw-mt-4">
								<PrimaryBtn
									onClick={handleSubmit}
									loading={loading}
									text={"Create"}
									width={
										"tw-bg-main tw-w-full tw-h-12 tw-rounded-xl tw-font-medium tw-text-base tw-text-white"
									}
								/>
							</div>
						</form>
					</div>
				</div>
			</ModalContainer>
		</div>
	);
};
