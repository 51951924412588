/* eslint-disable no-undef */
import { createElement } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useAuthStore from "./data/stores/authstore";

const GeneratePage = (pageName, folder) => {
	const component = () => require(`./${folder}/${pageName}`).default;
	let navigate = useNavigate();
	try {
		return createElement(component());
	} catch (e) {
		console.log(e);
		navigate("/");
	}
};

const PageRender = () => {
	const { page, id, step } = useParams(),
		{ isAuth, user } = useAuthStore();

	let pageName = "";
	if (step) {
		pageName = `${page}/${id}/${"[id]"}`;
	} else if (id) {
		pageName = `${page}/${"[id]"}`;
	} else {
		pageName = `${page}`;
	}

	return GeneratePage(
		pageName,
		isAuth && user?.privilege === "superadmin"
			? "pages"
			: isAuth
			? "views"
			: "screens"
	);
};

export default PageRender;
