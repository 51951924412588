import OtpInput from "react18-otp-input";
import { PrimaryBtn } from "../components/button";
import { PasswordInput } from "../components/inputs/myinput";
import { AuthTemplate } from "./login";
import { PiPassword } from "react-icons/pi";
import { toast } from "react-toastify";
import { apiCall } from "../data/stores/authstore";
import useErrorStore from "../data/stores/errorstore";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Otp = () => {
	const navigate = useNavigate();

	const init = {},
		[state, setState] = useState(init),
		textChange = e => {
			let { name, value } = e.target;
			setState({ ...state, [name]: value });
		},
		[loading, setLoading] = useState(null),
		{ returnErrors } = useErrorStore();
	let handleSubmit = async e => {
		e?.preventDefault();
		let errArr2 = [];
		if (!state?.password) errArr2?.push("Email is required");
		if (state?.password && state?.password !== state?.confirmPassword)
			errArr2?.push("Password do not match");
		if (errArr2?.length > 0) return errArr2?.forEach(it => toast.info(it));
		setLoading(true);
		let newState = { ...state };

		let { response, errArr, errMsg } = await apiCall(
			"post",
			`/api/v1/user/reset-password`,
			newState
		);
		// console.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			navigate("/login");
			return;
		}
		setLoading(false);
	};
	return (
		<>
			<AuthTemplate
				title={"Reset Password"}
				icon={<PiPassword size={30} color="#1dc4e9" />}>
				<div className="tw-mt-5">
					<form className="tw-mt-4">
						<div className="tw-space-y-4">
							<div>
								<div className="tw-pb-3">
									<strong className="tw-text-base tw-font-medium tw-text-main rowdies">
										OTP
									</strong>
									<small className="tw-block">
										Enter the code sent to your mail
									</small>
								</div>
								<OtpInput
									numInputs={6}
									shouldAutoFocus={true}
									isInputNum={true}
									renderSeparator={<span> - </span>}
									renderInput={props => <input {...props} />}
									// inputStyle={"tw-h-12 tw-border tw-text-xl  w-100"}
									className="otp-code__input tw-outline-0"
									// containerStyle={{ width: "300px" }}
									value={state?.otp}
									onChange={otp => setState({ ...state, otp })}
									isInputNumber={true}
								/>
							</div>
							<PasswordInput
								label={"New Password"}
								value={state?.password}
								name="password"
								onChange={textChange}
							/>
							<PasswordInput
								label={"Confirm New Password"}
								value={state?.confirmPassword}
								name="confirmPassword"
								onChange={textChange}
							/>
						</div>
						<div className="tw-mt-12">
							<PrimaryBtn
								onClick={handleSubmit}
								loading={loading}
								text={"Reset"}
								width={
									"tw-bg-main tw-w-full tw-h-12 tw-rounded-xl tw-font-medium tw-text-base tw-text-white"
								}
							/>
						</div>
					</form>
				</div>
			</AuthTemplate>
		</>
	);
};

export default Otp;
