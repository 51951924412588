/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import useUserStore from "../../data/stores/userstore";
import { apiCall } from "../../data/stores/authstore";
import MainHeader from "../../components/mainheader";
import { Addbutton, LoadMore, MainPaginate } from "../../components/button";
import moment from "moment";
import CreateUser from "../../components/modals/create-user";
import { PageLoader } from "../../components/button/modalcontainer";
import { SearchInput } from "../../components/inputs/myinput";
import Edit from "../../assets/edit.svg";
import Delete from "../../assets/delete.svg";
import DeleteModal, {
	SuspendRestoreModal,
} from "../../components/modals/deletemodal";
import { toast } from "react-toastify";
import useErrorStore from "../../data/stores/errorstore";

const Users = () => {
	const {
			data,
			getUsers,
			isFound,
			mainSearch,
			getSearch,
			getSearchUser,
			resetUserSearch,
		} = useUserStore(),
		[datum, setDatum] = useState(null),
		[isOpen, setIsOpen] = useState(null),
		[search, setSearch] = useState(""),
		[loading, setLoading] = useState(null);

	// useEffect(() => {
	// 	apiCall("get", "/api/v1/management/manage-users?privilege=admin", null, getUsers);

	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	useEffect(() => {
		if (isFound) setDatum(mainSearch);
		else setDatum(data);
	}, [data, isFound, mainSearch]);

	useEffect(() => {
		if (search) {
			document.getElementById("Search").addEventListener("search", () => {
				resetUserSearch();
			});
			let handleSubmit = async () => {
				if (!search) return;

				await handleLoadMore();
			};
			handleSubmit();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search]);
	let handleLoadMore = async str => {
		setLoading(str || "");
		if (search) {
			getSearch(search);
		}

		await apiCall(
			"get",
			`/api/v1/management/manage-users?privilege=admin${
				str ? `&limit=${datum?.limit * datum?.nextPage}` : ""
			}${search ? `&search=${search}` : ""}`,
			null,
			search ? getSearchUser : getUsers
		);
		setLoading(false);
	};

	if (!datum && !data) return <PageLoader />;

	return (
		<>
			<MainHeader text={"Users"} />
			<div className="tw-mx-auto tw-p-5 tw-min-h-3/4">
				<div className="tw-flex tw-justify-between tw-items-center tw-mb-3">
					<div className="">
						<h4 className="tw-text-base rowdies tw-font-normal tw-text-plain">
							All Users
						</h4>
						<SearchInput
							search={search}
							setSearch={setSearch}
							placeholder={"Search Users'"}
						/>
					</div>
					<Addbutton
						text={"Add User"}
						add={true}
						onClick={() => setIsOpen(true)}
					/>
				</div>

				<UsersList
					datum={datum}
					loading={loading}
					handleLoadMore={handleLoadMore}
					setLoading={setLoading}
				/>
			</div>
			{isOpen && <CreateUser handleClose={() => setIsOpen("")} />}
		</>
	);
};

export default Users;

export const UsersList = ({
	datum,
	loading,
	handleLoadMore,
	setLoading,
	homepage,
}) => {
	const [range] = useState(10),
		[page, setPage] = useState(1),
		[modal, setModal] = useState(""),
		[isDeleted, setIsDeleted] = useState(null),
		{ deleteUser, updateUser } = useUserStore(),
		{ returnErrors } = useErrorStore();

	let handleDelete = async e => {
		e?.preventDefault();
		setLoading(modal);
		let { response, errArr, errMsg } = await apiCall(
			"put",
			`/api/v1/management/manage-users`,
			{ type: modal, user: isDeleted?._id }
		);
		// console.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			setModal("done");
			if (modal === "delete") deleteUser(isDeleted);
			else updateUser(response);
			return;
		}
		setLoading(false);
	};

	const [itemOffset, setItemOffset] = useState(0);
	const endOffset = itemOffset + range;

	if (!datum) return;

	const currentItems = datum?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.docs?.length;
		setItemOffset(newOffset);
		setPage(1 + event?.selected);
	};

	return (
		<>
			<div className="tw-relative tw-overflow-x-auto tw-shadow-md sm:tw-rounded-lg">
				<table className="tw-w-full tw-text-sm tw-text-left tw-text-gray-500">
					<thead className="tw-text-md tw-text-white tw-capitalize tw-bg-secondary">
						<tr>
							<th scope="col" className="tw-p-4">
								<div className="tw-flex tw-items-center">
									<input
										id="checkbox-all-search"
										type="checkbox"
										className="tw-w-4 tw-h-4 tw-text-blue-600 tw-bg-gray-100 tw-border-gray-300 tw-rounded focus:tw-ring-blue-500 dark:focus:tw-ring-blue-600 dark:tw-ring-offset-gray-800 dark:focus:tw-ring-offset-gray-800 focus:tw-ring-2 dark:tw-bg-gray-700 dark:tw-border-gray-600"
									/>
									<label htmlFor="checkbox-all-search" className="tw-sr-only">
										checkbox
									</label>
								</div>
							</th>
							<th scope="col" className="tw-px-6 tw-py-3">
								First Name
							</th>
							<th scope="col" className="tw-px-6 tw-py-3">
								Last Name
							</th>
							<th scope="col" className="tw-px-6 tw-py-3">
								Email
							</th>
							<th scope="col" className="tw-px-6 tw-py-3">
								Last Active
							</th>
							<th scope="col" className="tw-px-6 tw-py-3">
								Status
							</th>
							<th scope="col" className="tw-px-6 tw-py-3">
								Date&Time
							</th>
							<th scope="col" className="tw-px-6 tw-py-3"></th>
						</tr>
					</thead>
					<tbody>
						{currentItems?.map((prod, i) => (
							<tr
								key={i}
								className="tw-bg-white tw-border-b hover:tw-bg-gray-50">
								<td className="tw-w-4 tw-p-4">
									<div className="tw-flex tw-items-center">
										<input
											id="checkbox-table-search-1"
											type="checkbox"
											className="tw-w-4 tw-h-4 tw-text-blue-600 tw-bg-gray-100 tw-border-gray-300 tw-rounded focus:tw-ring-blue-500 dark:focus:tw-ring-blue-600 dark:tw-ring-offset-gray-800 dark:focus:tw-ring-offset-gray-800 focus:tw-ring-2 dark:tw-bg-gray-700 dark:tw-border-gray-600"
										/>
										<label
											htmlFor="checkbox-table-search-1"
											className="tw-sr-only">
											checkbox
										</label>
									</div>
								</td>
								<th
									scope="row"
									className="tw-px-6 tw-py-4 tw-font-medium tw-text-gray-900 tw-whitespace-nowrap dark:tw-text-white tw-cursor-pointer">
									{prod?.firstName}
								</th>
								<th
									scope="row"
									className="tw-px-6 tw-py-4 tw-font-medium tw-text-gray-900 tw-whitespace-nowrap dark:tw-text-white tw-cursor-pointer">
									{prod?.lastName}
								</th>
								<th
									scope="row"
									className="tw-px-6 tw-py-4 tw-font-medium tw-text-gray-900 tw-whitespace-nowrap dark:tw-text-white tw-cursor-pointer">
									{prod?.email}
								</th>
								<th
									scope="row"
									className="tw-px-6 tw-py-4 tw-font-medium tw-text-gray-900 tw-whitespace-nowrap dark:tw-text-white tw-cursor-pointer">
									{prod?.lastVisited
										? moment(prod?.lastVisited).format("L hh:mm A")
										: null}
								</th>
								<th
									scope="row"
									className="tw-px-6 tw-py-4 tw-font-medium tw-text-gray-900 tw-whitespace-nowrap dark:tw-text-white tw-cursor-pointer">
									{prod?.statusText}
								</th>
								<th
									scope="row"
									className="tw-px-6 tw-py-4 tw-font-medium tw-text-gray-900 tw-whitespace-nowrap dark:tw-text-white tw-cursor-pointer">
									{moment(prod?.createdAt).format("L hh:mm A")}
								</th>
								<th
									scope="row"
									className="tw-px-6 tw-py-4 tw-font-medium tw-text-gray-900 tw-whitespace-nowrap dark:tw-text-white tw-cursor-pointer">
									{!homepage && (
										<div className="tw-mt-3 tw-flex tw-justify-center tw-items-center tw-gap-3">
											<img
												onClick={() => {
													setModal(prod?.status ? "disable" : "enable");
													setIsDeleted(prod);
												}}
												src={Edit}
												alt=""
												className="tw-cursor-pointer"
											/>
											<img
												onClick={() => {
													setModal("delete");
													setIsDeleted(prod);
												}}
												src={Delete}
												alt=""
												className="tw-cursor-pointer"
											/>
										</div>
									)}
								</th>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			{!homepage && (
				<>
					<div className="tw-mt-4 tw-flex tw-justify-center">
						<LoadMore
							next={page === pageCount && datum?.hasNextPage}
							loading={loading === "loadmore"}
							handleLoadMore={() => handleLoadMore("loadmore")}
						/>
					</div>
					{pageCount > 1 && (
						<MainPaginate
							pageCount={pageCount}
							handlePageClick={handlePageClick}
						/>
					)}
				</>
			)}
			{modal === "delete" && (
				<DeleteModal
					handleClose={() => setModal("")}
					item={isDeleted}
					onClick={handleDelete}
					loading={loading === "delete"}
				/>
			)}
			{["disable", "enable"]?.includes(modal) && (
				<SuspendRestoreModal
					handleClose={() => setModal("")}
					item={isDeleted}
					onClick={handleDelete}
					loading={loading && loading !== "delete"}
					type={modal}
				/>
			)}
		</>
	);
};
