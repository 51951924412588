/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import useActivityStore from "../../data/stores/activitystore";
import { apiCall } from "../../data/stores/authstore";
import { PageLoader } from "../../components/button/modalcontainer";
import MainHeader from "../../components/mainheader";
import { SearchInput } from "../../components/inputs/myinput";
import { LoadMore, MainPaginate } from "../../components/button";
import moment from "moment";

export const MainActivity = () => {
	const {
			data,
			getActivity,
			isFound,
			mainSearch,
			getSearch,
			getSearchActivity,
			resetActivitySearch,
		} = useActivityStore(),
		[datum, setDatum] = useState(null),
		[loading, setLoading] = useState(null),
		[search, setSearch] = useState("");

	useEffect(() => {
		apiCall("get", "/api/v1/activity/manage-activities", null, getActivity);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (isFound) setDatum(mainSearch);
		else setDatum(data);
	}, [data, isFound, mainSearch]);

	useEffect(() => {
		if (search) {
			document.getElementById("Search").addEventListener("search", () => {
				resetActivitySearch();
			});
			let handleSubmit = async () => {
				if (!search) return;

				await handleLoadMore();
			};
			handleSubmit();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search]);
	let handleLoadMore = async str => {
		setLoading(str || "");
		if (search) {
			getSearch(search);
		}

		await apiCall(
			"get",
			`/api/v1/activity/manage-activities?a=a${
				str ? `&limit=${datum?.limit * datum?.nextPage}` : ""
			}${search ? `&search=${search}` : ""}`,
			null,
			search ? getSearchActivity : getActivity
		);
		setLoading(false);
	};

	if (!datum && !data) return <PageLoader />;

	return (
		<>
			<MainHeader text={"Activity"} />
			<div className="tw-mx-auto tw-p-5 tw-min-h-3/4">
				<div className="tw-flex tw-justify-between tw-items-center tw-mb-3">
					<div className="">
						<h4 className="tw-text-base rowdies tw-font-normal tw-text-plain">
							All Activities
						</h4>
						<SearchInput
							search={search}
							setSearch={setSearch}
							placeholder={"Search Logs'"}
						/>
					</div>
				</div>

				<ActivtiesList
					datum={datum}
					loading={loading}
					handleLoadMore={handleLoadMore}
				/>
			</div>
		</>
	);
};

const Activity = () => <MainActivity />;

export default Activity;

export const ActivtiesList = ({ datum, loading, handleLoadMore, homepage }) => {
	const [range] = useState(10),
		[page, setPage] = useState(1);

	const [itemOffset, setItemOffset] = useState(0);
	const endOffset = itemOffset + range;

	if (!datum) return;

	const currentItems = datum?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.docs?.length;
		setItemOffset(newOffset);
		setPage(1 + event?.selected);
	};

	return (
		<>
			<div className="tw-relative tw-overflow-x-auto tw-shadow-md sm:tw-rounded-lg">
				<table className="tw-w-full tw-text-sm tw-text-left tw-text-gray-500">
					<thead className="tw-text-md tw-text-white tw-capitalize tw-bg-secondary">
						<tr>
							<th scope="col" className="tw-p-4">
								<div className="tw-flex tw-items-center">
									<input
										id="checkbox-all-search"
										type="checkbox"
										className="tw-w-4 tw-h-4 tw-text-blue-600 tw-bg-gray-100 tw-border-gray-300 tw-rounded focus:tw-ring-blue-500 dark:focus:tw-ring-blue-600 dark:tw-ring-offset-gray-800 dark:focus:tw-ring-offset-gray-800 focus:tw-ring-2 dark:tw-bg-gray-700 dark:tw-border-gray-600"
									/>
									<label htmlFor="checkbox-all-search" className="tw-sr-only">
										checkbox
									</label>
								</div>
							</th>
							<th scope="col" className="tw-px-6 tw-py-3">
								User
							</th>
							<th scope="col" className="tw-px-6 tw-py-3">
								Action
							</th>
							<th scope="col" className="tw-px-6 tw-py-3">
								Date&Time
							</th>
						</tr>
					</thead>
					<tbody>
						{currentItems?.map((prod, i) => (
							<tr
								key={i}
								className="tw-bg-white tw-border-b hover:tw-bg-gray-50">
								<td className="tw-w-4 tw-p-4">
									<div className="tw-flex tw-items-center">
										<input
											id="checkbox-table-search-1"
											type="checkbox"
											className="tw-w-4 tw-h-4 tw-text-blue-600 tw-bg-gray-100 tw-border-gray-300 tw-rounded focus:tw-ring-blue-500 dark:focus:tw-ring-blue-600 dark:tw-ring-offset-gray-800 dark:focus:tw-ring-offset-gray-800 focus:tw-ring-2 dark:tw-bg-gray-700 dark:tw-border-gray-600"
										/>
										<label
											htmlFor="checkbox-table-search-1"
											className="tw-sr-only">
											checkbox
										</label>
									</div>
								</td>
								<th
									scope="row"
									className="tw-px-6 tw-py-4 tw-font-medium tw-text-gray-900 tw-whitespace-nowrap dark:tw-text-white tw-cursor-pointer">
									{prod?.user?.firstName} {prod?.user?.lastName}
								</th>
								<th
									scope="row"
									className="tw-px-6 tw-py-4 tw-font-medium tw-text-gray-900 tw-whitespace-nowrap dark:tw-text-white tw-cursor-pointer">
									{prod?.message}
								</th>
								<th
									scope="row"
									className="tw-px-6 tw-py-4 tw-font-medium tw-text-gray-900 tw-whitespace-nowrap dark:tw-text-white tw-cursor-pointer">
									{moment(prod?.createdAt).format("L hh:mm A")}
								</th>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			{!homepage && (
				<>
					<div className="tw-mt-4 tw-flex tw-justify-center">
						<LoadMore
							next={page === pageCount && datum?.hasNextPage}
							loading={loading === "loadmore"}
							handleLoadMore={() => handleLoadMore("loadmore")}
						/>
					</div>
					{pageCount > 1 && (
						<MainPaginate
							pageCount={pageCount}
							handlePageClick={handlePageClick}
						/>
					)}
				</>
			)}
		</>
	);
};
