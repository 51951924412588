/* eslint-disable react/prop-types */
import ReactPaginate from "react-paginate";
import { ClipLoader } from "react-spinners";
import { IconContext } from "react-icons";
import {
	AiFillEye,
	AiOutlineUsergroupAdd,
	// AiTwotoneCalendar,
} from "react-icons/ai";
import { BiCopy } from "react-icons/bi";
import { BsPlusSquare } from "react-icons/bs";
// import { useNavigate } from "react-router-dom";

export const Addbutton = ({
	onClick,
	create,
	add,
	text,
	view,
	bulk,
	space,
	copy,
}) => {
	// const navigate = useNavigate();
	return (
		<div>
			<button
				style={{
					background: "black !important",
				}}
				className={`tw-bg-black btn tw-h-8 tw-shadow-xl tw-px-3 hover:tw-scale-110 hover:tw-transition hover:tw-transform hover:tw-ease-out hover:tw-duration-800 hover:tw-delay-400 tw-flex tw-justify-center tw-items-center tw-gap-3 tw-rounded-md tw-text-white tw-whitespace-nowrap tw-text-sm ${
					space ? "tw-mx-3" : ""
				}`}
				onClick={onClick}>
				<div>
					{copy ? (
						<IconContext.Provider value={{ color: "white" }}>
							<BiCopy size={15} />
						</IconContext.Provider>
					) : create ? (
						<IconContext.Provider value={{ color: "white" }}>
							<BsPlusSquare size={15} />
						</IconContext.Provider>
					) : add ? (
						<IconContext.Provider value={{ color: "white" }}>
							<BsPlusSquare size={15} />
						</IconContext.Provider>
					) : view ? (
						<IconContext.Provider value={{ color: "white" }}>
							<AiFillEye size={15} />
						</IconContext.Provider>
					) : bulk ? (
						<IconContext.Provider value={{ color: "white" }}>
							<AiOutlineUsergroupAdd size={15} />
						</IconContext.Provider>
					) : null}
				</div>
				<p className="tw-text-white tw-text-xs manrope tw-animate-pulse">
					{text}
				</p>
			</button>
		</div>
	);
};

export const MainPaginate = ({ handlePageClick, pageCount }) => (
	<div className="tw-flex tw-justify-center tw-items-center tw-py-10">
		<ReactPaginate
			breakLabel="..."
			onPageChange={handlePageClick}
			pageRangeDisplayed={5}
			pageCount={pageCount}
			renderOnZeroPageCount={null}
			pageClassName="tw-h-10 tw-w-10 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-text-xl tw-font-medium manrope tw-border tw-border-main tw-mx-2 tw-cursor-pointer"
			className="tw-flex tw-items-center tw-justify-center"
			previousClassName="tw-hidden"
			nextClassName="tw-hidden"
			activeClassName="tw-bg-[#021C2F] tw-text-white"
		/>
	</div>
);

export const PrimaryBtn = ({
	text,
	bg,
	color,
	onClick,
	width,
	disabled,
	height,
	...restProps
}) => {
	return (
		<div>
			<button
				disabled={disabled || restProps?.loading}
				onClick={onClick}
				style={{
					background: bg,
					color: color,
				}}
				className={`${height || "tw-h-10"} ${width || "tw-min-w-24"} ${
					(disabled || restProps?.loading) &&
					"tw-cursor-not-allowed tw-bg-opacity-40"
				} tw-px-5 tw-rounded-lg tw-text-base satoshi tw-font-medium`}>
				{text}{" "}
				{restProps?.loading && (
					<ClipLoader color={restProps?.loadCss || "white"} size={12} />
				)}
			</button>
		</div>
	);
};

export const LoadMore = ({ handleLoadMore, next, loading }) => {
	return (
		<>
			{!next ? (
				""
			) : (
				<PrimaryBtn
					onClick={handleLoadMore}
					text={loading ? "Loading..." : "Load More"}
					loading={loading}
					css="tw-mx-auto"
					type={"button"}
					buttonType={"primary"}
					width={"w"}
				/>
			)}
		</>
	);
};
