/* eslint-disable react/prop-types */
import { useNavigate } from "react-router-dom";
import LogoImg from "../../assets/GC.png";

const Logo = ({ height, css }) => {
	let navigate = useNavigate();
	return (
		<div>
			<img
				onClick={() => navigate("/")}
				src={LogoImg}
				alt=""
				className={`tw-cursor-pointer ${css || ""} tw-rounded-full`}
				style={{ height: height }}
			/>
		</div>
	);
};

export default Logo;
