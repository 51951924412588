/* eslint-disable react/prop-types */
import { useState } from "react";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import Search from "../../assets/search-normal.svg";

const Myinput = ({
	label,
	onChange,
	type,
	value,
	name,
	width,
	height,
	...restProps
}) => {
	return (
		<div>
			<div>
				<strong className="tw-text-base tw-font-medium tw-text-main rowdies">
					{label}
				</strong>
			</div>
			{type === "textarea" ? (
				<textarea
					value={value}
					onChange={onChange}
					name={name}
					className={`${width || "tw-w-full"} ${
						height || "tw-h-12"
					} tw-border-0.5 tw-border-[#00222480] tw-pl-6 tw-mt-2 tw-rounded-xl tw-text-base tw-font-normal tw-text-secondary`}
					{...restProps}
					style={{ resize: "none", height: "10rem" }}
				/>
			) : (
				<input
					type={type || "text"}
					value={value}
					onChange={onChange}
					name={name}
					className={`${width || "tw-w-full"} ${
						height || "tw-h-12"
					} tw-border tw-border-[#00222480] tw-pl-6 tw-mt-2 tw-rounded-xl tw-text-base tw-font-normal tw-text-secondary`}
					{...restProps}
				/>
			)}
		</div>
	);
};

export const SelectInput = ({
	label,
	name,
	options,
	onChange,
	value,
	width,
}) => {
	return (
		<div>
			<div>
				<div>
					<small className="tw-text-base tw-font-medium tw-text-main rowdies">
						{label}
					</small>
				</div>
				<select
					onChange={onChange}
					value={value}
					name={name}
					className={`${
						width || "tw-w-full"
					} tw-pl-6 tw-capitalize tw-h-12 tw-rounded-md tw-text-base tw-font-medium satoshi tw-text-secondary tw-border tw-border-[#00222480]`}
					id="">
					<option value="" selected>
						Choose Value
					</option>
					{options?.map((it, i) => (
						<option
							key={i}
							value={
								it?._id || it?.code || it?.name || it?.title || it?.value || it
							}>
							{name === "stateOfResidence" ? (
								<>
									{it?.name}: {it?.capital}
								</>
							) : (
								it?.title || it?.value || it?.name || it
							)}
						</option>
					))}
				</select>
			</div>
		</div>
	);
};

export const PasswordInput = ({
	label,
	onChange,
	value,
	name,
	width,
	height,
	...restProps
}) => {
	const [show, setShow] = useState();
	return (
		<div>
			<div>
				<strong className="tw-text-base tw-font-medium tw-text-main rowdies">
					{label}
				</strong>
			</div>
			<div
				className={`${width || "tw-w-full"} ${
					height || "tw-h-12"
				} tw-relative`}>
				<input
					type={show ? "text" : "password"}
					value={value}
					name={name}
					onChange={onChange}
					className="tw-h-full tw-pl-6 tw-w-full tw-border tw-border-[#00222480] tw-mt-2 tw-rounded-xl tw-text-base tw-font-normal tw-text-secondary"
					{...restProps}
				/>
				<div
					onClick={() => setShow(!show)}
					className="tw-absolute tw-top-6 tw-right-4 tw-cursor-pointer">
					{show ? <BsEyeSlash size={15} /> : <BsEye size={15} />}
				</div>
			</div>
		</div>
	);
};

export const SearchInput = ({ placeholder, setSearch, search }) => {
	return (
		<div className="tw-border-[0.7px] tw-border-[#7B87944D] tw-rounded-full tw-h-10 tw-w-96 tw-relative">
			<div className="tw-absolute tw-top-3 tw-left-2">
				<img src={Search} alt="" className="" />
			</div>
			<input
				className="tw-w-full tw-h-full tw-border-inherit tw-pl-8 tw-rounded-full tw-text-sm satoshi tw-font-normal tw-text-secondary tw-outline-0"
				placeholder={placeholder || "Search..."}
				required
				type="search"
				name="search"
				id="Search"
				value={search}
				onChange={e => setSearch(e.target.value)}
			/>
		</div>
	);
};

export default Myinput;
