import { toast } from "react-toastify";
import { PrimaryBtn } from "../components/button";
import Myinput from "../components/inputs/myinput";
import { AuthTemplate } from "./login";
import { TfiUnlock } from "react-icons/tfi";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import useErrorStore from "../data/stores/errorstore";
import { apiCall } from "../data/stores/authstore";

const Forgotpassword = () => {
	const methods = useForm({
		defaultValues: {
			email: "",
			password: "",
		},
	});


	const navigate = useNavigate(),
		[loading, setLoading] = useState(null),
		{ returnErrors } = useErrorStore();
	let handleSubmit = async data => {
		let errArr2 = [];
		if (!data?.email) errArr2?.push("Email is required");
		if (errArr2?.length > 0) return errArr2?.forEach(it => toast.info(it));
		setLoading(true);
		let newState = { ...data };

		let { response, errArr, errMsg } = await apiCall(
			"put",
			`/api/v1/user/reset-password`,
			newState
		);
		// console.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			navigate("/otp");
			return;
		}
		setLoading(false);
	};

	return (
		<>
			<AuthTemplate
				title={"Forgot Password"}
				icon={<TfiUnlock size={30} color="#1dc4e9" />}>
				<FormProvider {...methods}>
					<form onSubmit={methods.handleSubmit(handleSubmit)}>
						<div className="tw-space-y-4">
							{/* Email */}
							<Controller
								name="email"
								control={methods.control}
								rules={{
									required: "This field is required",
									pattern: {
										value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/g,
										message: "You must enter a valid email address",
									},
								}}
								render={({ field: { onChange, name } }) => (
									<fieldset className="">
										<Myinput
											label={"Email"}
											type={"email"}
											onChange={onChange}
											name={name}
										/>
										{methods.formState.errors.email && (
											<span className="tw-text-xs tw-font-bold tw-text-red-600 tw-block">
												{methods.formState.errors.email.message}
											</span>
										)}
									</fieldset>
								)}
							/>
						</div>
						<div className="tw-mt-12">
							{/* <button className="tw-bg-scblue tw-w-full tw-h-12 tw-rounded-xl tw-font-medium tw-text-base tw-text-white">
								Login
							</button> */}
							<PrimaryBtn
								onClick={handleSubmit}
								loading={loading}
								text={"Send"}
								width={
									"tw-bg-main tw-w-full tw-h-12 tw-rounded-xl tw-font-medium tw-text-base tw-text-white"
								}
							/>
						</div>
					</form>
				</FormProvider>
			</AuthTemplate>
		</>
	);
};

export default Forgotpassword;
